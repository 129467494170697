@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Nunito:wght@300;400;600;700&family=Poppins:wght@300;400;600;700&display=swap");

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}
.font-montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.font-nunito {
  font-family: "Nunito", sans-serif !important;
}
.font-barlow {
  font-family: "Quicksand", sans-serif !important;
}
