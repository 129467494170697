.orderTopWrap {
  text-align: center;
  position: relative;
  width: fit-content;
  margin: auto;
}
.questionImg {
  position: absolute;
  top: -55px;
  right: -80px;
  width: 80px;
}
.problem-btns-dv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.prob-btn {
  width: 280px;
  height: 50px;
  border-radius: 30px;
  border: none;
  background-color: #e6213c;
  color: #fff;
  font-size: 24px;
  margin-bottom: 15px;
}
